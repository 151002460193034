import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import NewsLetter from "../../forms/newsletter-form"
import NewsLetterFooter from "../../forms/newsletter-form-footer"

const SubscribeSection = (props) => {

    const data = useStaticQuery(graphql`
    query Subscribe_Updates {  
      glstrapi{

        globalModule {
            Subscribe_Updates {
              Title
              Content
            }
        }

      }
    }`)

    var Subscribe_Updates = data.glstrapi?.globalModule?.Subscribe_Updates;
    return (
        <>
            <div className={`subscribe`}>
                <h2>{Subscribe_Updates.Title}</h2>
                <p>{Subscribe_Updates.Content}</p>

                <div className="form-field">
                    {props.isFooter ? <NewsLetterFooter/> : <NewsLetter />}
                </div>
            </div>
            
        </>
    );
};
export default SubscribeSection;