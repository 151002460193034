import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";
import { menuLinks } from "../common/pageUtils"
import PhoneIcon from '../../images/footer/phoneIcon.svg'

const FooterMenu = (props) => {
  const [active_submenu, setActiveSubMenu] = useState(-1);
  var menu_data = props.Menus;
  var isContact;

  return (
    <>
      <div className={`footer-menu`}>
        <Col>
          <div className={`menu-wrapper ${props.isActive ? `open-menu-wrapper` : ``}`}>
            <div className="open">
              <h5><Link to={'/' + props.CTA_Link + '/'}>{props.CTA_Label}</Link></h5>
              <i onClick={() => props.clickHandler(props.i)} className={props.active_menu === props.i ? "icon-minus" : "icon-plus"}></i>
            </div>
            <div className={`menu-list ${props.active_menu === props.i ? "show" : "hide"}`}>
              <ul>
                {menu_data && menu_data.map((menu, i) => {
                  return (
                    <li key={i}>
                      <Link to={menuLinks(menu.Link)}>
                        {menu.Label}
                      </Link> 
                    </li>
                  )
                }
                )}
              </ul>
            </div>
          </div>
        </Col>
      </div>
    </>
  );
};
export default FooterMenu;
