import React, { useState, useEffect } from "react"

import { Form, Button } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import { Mail_Function, CustomLinks, CampaignMonitorToken, CampaignMonitor, CampaignMonitorDirect } from "../common/utils"
import { navigate } from "@reach/router"
import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"

function NewsletterForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectError, setSelectError] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  //console.log("NameField",props.NameField);

  const fields = ([
      {
        element: "config",
        formname: "Newsletter",
        error_text: "Highlighted fields are required.",
        success_text: "Thank you for subscribing.",
        email_temp_user: "newsletter_user",
        email_temp_admin: "newsletter_admin",
        email_server_func: "newsletter",
        event_tracking: "newsletter",
        page_url: "/insights/property-news",
        email_subject_user: "Newsletter Subscription",
        email_subject_admin: "Newsletter Subscription",
      },
      {
        grpmd: "12",
        label: "",
        placeholder: "Which newsletters interest you?",
        name: "news_updates",
        element: "select",
        required: true,
        values: ['Select', 'Auction Updates', 'Commercial Investments', 'Commercial News', 'Residential Investments', 'Residential News'],
        labelClass: "annual-txt",
        class: "full_wdth_box",
        multiple:true,
        errorMsg: "Select the updates."
      },
      {
        grpmd: "",
        label: "",
        placeholder: "Email Address",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        class: "newsletter_field",
        grpcls: "d-flex align-items-center justify-content-between",
        errorMsg: "Enter a valid email id, Ex: james123@gmail.com, JAMES123@GMAIL.COM."
      },
      {
        name: "subscribe",
        type:"submit",
        element: "button",
        value: "Subscribe",
        class: "btn btn-secondary",
        customClass: "",
        iconElement: <i className="arrow-right"></i>
      },
      {
        text: '<p>You can unsubscribe or adjust your preferences at any time. To learn more, you can view our <a href="'+CustomLinks.privacy_policy+'" target="_blank">privacy policy</a> here.</p>',
        element: "html",
        class: "mb-md-0 mb-3 content_r-m help-text"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = (event, type) => {
    // remove initial empty spaces

    if(type === "select"){
      //event.value = event.value.trimStart()
      if(event.length > 0){
        var filter_val = event.filter(item => item.value !== "Select");
        setSelectedOptions(filter_val);
      } else{
        setSelectedOptions(event);
      }
    } else{
      event.target.value = event.target.value.trimStart()
    }
  }

  const CampaignMonitor = (form_values) =>{
    var response2 = CampaignMonitorDirect(form_values).then(response => {
      //console.log("response 1 ===>", response)
    }).catch(err =>
      console.log("campaign subscribe err", err)
    );
    //console.log("response", response2);
  }

  useEffect(() => {
    if (token !== '') {

      const processFromData = async () => {

      let formData = new FormData();
      formvalues['g-recaptcha-response'] = token;

      formData.append('data', JSON.stringify(formvalues));

      //console.log("formData", formvalues);
      
        if(formvalues.email){
          await postFormData(formData).then(async apiRes => {

            window.grecaptcha.reset(); 
    
          });
          Mail_Function(formvalues);
            //await CampaignMonitorDirect(formvalues);

            var startTime = 1000;
            await formvalues.news_updates.map(async(item, index)=> {
              var form_values = {...formvalues,news_updates:item}
              var increment_time = parseInt(index) * 200;
              startTime = parseInt(startTime) + increment_time;

              //console.log("startTime", startTime);

              await setTimeout(() => {
                var res = CampaignMonitor(form_values);            
              }, startTime);

            })
          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+fields[0].event_tracking,
            'formId': 'form-'+fields[0].event_tracking,
            'formName': fields[0].formname,
            'formLabel': fields[0].formname
          });

          setShowerror(false);
          setTimeout(() => {
            sessionStorage.setItem("previousFormPage", window.location.pathname)
            navigate("/thank-you")
          }, 200);
          myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    var form_obj_arr = Object.entries(form);

    //console.log("form_obj_arr =>", form_obj_arr.filter(item => item[1].required === true));

    form_obj_arr.filter(item => item[1].required === true).map(item =>{
      if(item[1].validationMessage !== ""){
        $(".error_msg."+item[1].name).addClass('show');
      } else{
        $(".error_msg."+item[1].name).removeClass('show');
      }
    })
    
    if(selectedOptions.length === 0){
      setSelectError(true)
      $(".error_msg.news_updates").addClass('show');
    } else{
      setSelectError(false)
      $(".error_msg.news_updates").removeClass('show');
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);

      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
    else {

      event.preventDefault();
      

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      var news_updates = selectedOptions.map(item => item.value);
      //console.log("news_updates ==>", news_updates);

      json['name'] = json.email;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;
      json['news_updates'] = news_updates;
      json['extra'] = {"news_updates": news_updates, "subject" : json.email_subject_user};

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      setSelectError(false);
      setSelectedOptions([]);

      // reset form
      const form = event.target
      form.reset();
      
      
    }
  };

  return (
    <>
      <div className="form">
        <div ref={myRef} />

        {/* {showerror && <div className="alert alert-danger">
          {fields[0].error_text}
        </div>} */}

        {showthankyou && <div className="alert alert-success">
          {fields[0].success_text}
        </div>}

        
          <Form name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
            <input type="hidden" name="form_name" value={fields[0].formname} />
            <input type="hidden" name="form_type" value={fields[0].form_type} />
            <input type="hidden" name="to_email_id" value={props.to_email_id} />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="admin_email" value={"No"} />

            {fields.map((field, index) => {
                if ( "input" === field.element ) {
                  return (
                    <InputField
                        label={field.label}
                        name={field.name}
                        grpmd={field.grpmd}
                        ref={field.ref}
                        type={field.type}
                        fieldClass={field.class}
                        placeholder={field.placeholder}
                        labelClass={field.labelClass}
                        required={field.required}
                        key={`${field.element}~${index}`}
                        pattern={field.patternchk}
                        handlechange={handlechange}
                        grpcls={field.grpcls}
                        errorMsg={field.errorMsg}
                    />
                  );
                }
                if ("select" === field.element) {
                  return (
                    <SelectField
                      name={field.name}
                      grpmd={field.grpmd}
                      label={field.label}
                      ref={field.ref}
                      required={field.required}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      values={field.values}
                      selectedValue={selectedOptions}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                      componentprops={props}
                      divclass={field.divclass}
                      multiple={field.multiple}
                      isError={selectError ? true : '' }
                      errorMsg={field.errorMsg}
                    />
                  );
                }
                if ("textarea" === field.element) {
                  return (
                    <TextAreaField
                      name={field.name}
                      grpmd={field.grpmd}
                      ref={field.ref}
                      rows={field.rows}
                      fieldClass={field.class}
                      labelClass={field.labelClass}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.element}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("checkbox" === field.element) {
                  return (
                    <CheckboxField
                      name={field.name}
                      ref={field.ref}
                      value={field.value}
                      fieldClass={field.class}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={`${field.name}~${index}`}
                      handlechange={handlechange}
                    />
                  );
                }
                if ("html" === field.element) {
                  return (
                    <div className="full_wdth">
                      <HtmlBox
                        text={field.text}
                        fieldClass={field.class}
                        key={`${field.element}~${index}`}
                      />
                    </div>
                  );
                }
                if ("captcha" === field.element) {
                  return (
                    <ReCaptchaBox
                      fieldClass={field.class}
                      captRef={field.captchaRef}
                      key={`${field.element}~${index}`}
                      handleonVerify={handleonVerify}
                    />
                  );
                }
                if ("button" === field.element) {
                  return (
                    <ButtonField
                      name={field.name}
                      fieldClass={field.class}
                      type={field.type}
                      value={field.value}
                      key={`${field.element}~${index}`}
                      customClass={field.customClass}
                      iconElement={field.iconElement}
                    />
                  );
                }
              })
            }
                

          </Form>
        </div>
    </>
  );
}


const NewsletterFormPage = (props) => (
  <NewsletterForm {...props} />
)

export default NewsletterFormPage
